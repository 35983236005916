export const handleScrollHeader = () => {
  let oldScrollY = window.scrollY;
  const nav = document.querySelector(".nav");
  const headerElement = document.querySelector<HTMLElement>(".js-header");

  const handleHeader = () => {
    if (nav?.classList.contains("active")) return;

    oldScrollY < window.scrollY
      ? headerElement?.classList.remove("header-shown")
      : headerElement?.classList.add("header-shown");

    if (headerElement?.dataset["home"]) {
      window.scrollY > 0
        ? headerElement?.classList.remove("header-transparent")
        : headerElement?.classList.add("header-transparent");
    }

    oldScrollY = window.scrollY;
  };

  window.addEventListener("scroll", handleHeader);
};
