import GLightbox from "glightbox";

export const initLightbox = () =>
  GLightbox({
    selector: ".lightbox",
    touchNavigation: true,
    touchFollowAxis: true,
    loop: true,
    moreLength: 1000,
    skin: "",
  });
