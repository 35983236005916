import Embla from "embla-carousel";
import AutoHeight from "embla-carousel-auto-height";

const createCarousel = (mainContainer: HTMLElement) => {
  const mainElement = mainContainer.querySelector<HTMLElement>(".embla");
  const isAutoHeight = !!mainContainer.dataset["autoHeight"];

  if (!mainElement) return;

  const carousel = Embla(
    mainElement,
    {
      align: "start",
      loop: true,
    },
    isAutoHeight ? [AutoHeight()] : []
  );

  const leftArrowElement = mainElement.querySelector(".js-carousel-prev");
  const rightArrowElement = mainElement.querySelector(".js-carousel-next");

  leftArrowElement?.addEventListener("click", () => carousel.scrollPrev());
  rightArrowElement?.addEventListener("click", () => carousel.scrollNext());
};

export const initCarousels = () => {
  const carousels = document.querySelectorAll<HTMLElement>(".js-carousel");

  [...carousels].map((el) => createCarousel(el));
};
