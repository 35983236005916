const open = (hash: string) => {
  if (!hash.length) return;
  history.pushState(null, "", hash);
  document.querySelector(hash)?.classList.toggle("active");
};

const close = () => {
  history.pushState(null, "", location.pathname);
  document.querySelector(".js-popup.active")?.classList.toggle("active");
};

const handlePopup = (e: Event) => {
  const target = <HTMLElement | null>e.target;
  const linkEl = target?.closest<HTMLAnchorElement>(".js-popup-link");
  const closeEl = target?.closest(".js-popup-close");

  linkEl && (e.preventDefault(), open(linkEl.hash));

  closeEl && close();
};

export const initPopup = () => {
  window.addEventListener("load", () => open(location.hash));
  document.addEventListener("click", handlePopup);
  document.addEventListener("keyup", (e) => e.key === "Escape" && close());
};
