import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";
import "../css/entry.css";
import { initCarousels } from "./components/carousel";
import { initGallery } from "./components/gallery";
import { initLightbox } from "./components/lightbox";
import { initNavbarToggler } from "./components/navbar-toggler";
import { initPopup } from "./components/popup";
import { handleScrollHeader } from "./components/scroll-header";
import { handleUsercentricsButtons } from "./components/usercentrics-buttons";
import { initVideo } from "./components/video";
import registerErrorReporting from "./error-reporting";
import { initCrosswordInput } from "./components/crossword";

registerErrorReporting();
handleScrollHeader();
handleUsercentricsButtons();
initPopup();
initCarousels();
initNavbarToggler();
initGallery();
initLightbox();
initVideo();
initCrosswordInput();
