import de from "./de";
import it from "./it";

type Language = "de" | "it";

export const currentLanguage = <Language>document.documentElement.lang;

const i18n = {
  de,
  it,
}[currentLanguage];

export default i18n;
