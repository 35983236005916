export const initNavbarToggler = () => {
  const navbarTogglerOpenEl = document.querySelector(".js-navbar-toggler-open");
  const navbarTogglerCloseEl = document.querySelector(
    ".js-navbar-toggler-close"
  );
  const headerNav = document.querySelector(".nav");

  const toggleNav = () => headerNav?.classList.toggle("active");
  navbarTogglerOpenEl?.addEventListener("click", toggleNav);
  navbarTogglerCloseEl?.addEventListener("click", toggleNav);
};
